<template>
<div class="wrapper">
<!-- Navbar --> 
<topbar2></topbar2>
    <!-- /.navbar -->
  <!-- Main Sidebar Container -->
  <header2></header2>
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="">
      <div class="row">    
              <div class="col-md-8 col-12">
                 <div class="card-header">
                <h3 class="card-title"> Extra Charge List </h3>
              </div>
              </div>
               <div class="col-md-4 col-12">
                 <div class="srcpadmobie"> 
                 <div class="row">
              <div class="col-md-9 col-9">
                <input class="form-control topmsrc5 topm5" type="text" v-model="searchQuery" placeholder="Search" />
              </div>
               <div class="col-md-3 col-3 txtright">
                    <router-link to="/addextra" @click.prevent="toggleMenu">
                    <div class="btn btn-primary btn-sm topmsrc5 topm5"><i class="fa fa-plus nav-icon"></i> Add </div>
                    </router-link>
                  </div>
                 </div>
                 </div>
               </div>
            </div>
            <div class="hgtblank">&nbsp;</div>
      </div>
      
    </div>
    
        <!-- Main content -->
    <section class="content">
      <div class="container-fluid">        
        <!-- Main row -->
        <div class="row">
          <!-- Left col -->
          <section class="col-lg-12 connectedSortable">
            <!-- Custom tabs (Charts with tabs)-->
            <div class="">              
              <div class="">
                <div class="tab-content p-0">
                  <!-- Morris chart - Sales -->
                  <div class="chart tab-pane active">
   <div class="row">
<div class="col-md-12">
<div class="card card-primary cardpad">              
              <!-- /.card-header -->
<div class="card-body">

<table class="table">
  <tr>
    <th style="width: 80%;">Charge Name</th>
    <th>Amount</th>
    <th>Action</th>
  </tr>
  <tr v-for="d in filteredResources" v-bind:key="d.id">
    <td>
      <router-link  :to="{path:'/editextracharge',query:{id: d.id,name: d.name,amount: d.amount}}">
      <span class="linkdark">{{ d.name }}</span>
    </router-link>  
    </td>
    <td>Rs. {{ d.amount }}</td>
    <td>
    <router-link  :to="{path:'/editextracharge',query:{id: d.id,name: d.name,amount: d.amount}}" class="btn btn-success btn-xs">
      <i class="fas fa-edit"></i> 
    </router-link>  
    </td>
  </tr>
</table>

              
              </div>
              <!-- /.card-body -->
            </div>

        </div>
      </div>  

                   </div>                  
                </div>
              </div><!-- /.card-body -->
            </div>
            <!-- /.card -->
          </section>
          <!-- /.Left col -->
        </div>
        <!-- /.row (main row) -->
      </div><!-- /.container-fluid -->
    </section>

  </div>
  <footer2></footer2> 
</div>
</template>

<script>
import "@ocrv/vue-tailwind-pagination/dist/style.css";
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {  
  components:{        
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar    
      },
  data() {
    return {
      resources: [],      
      showbtn:this.id,
      searchQuery:'', 
      company_id:this.$store.state.auth.user.data[0].company_id       
    };
  },
  methods: {    
    async getData() {
      var response = await axios.post(
        `http://laundry.ezeehost.com:4909/ClientIspmateApi/extrachargelist`, {
          company_id: this.company_id,   
          page:'all'                  
        });      
      this.resources = response.data.data;
      this.total = response.data.total_record;
      //console.log(this.resources)
    }, 
       
  },
  computed: {
    filteredResources (){
      if(this.searchQuery){
      return this.resources.filter((d)=>{       
        return d.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      })
      }else{
        return this.resources;
      }
    }
  },
  mounted(){   
    setTimeout(() => {
      this.myData = 'Example Data';
    }, 2000);  
    this.getData();
  }
};
</script>

<style>
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem 1.25rem 1.25rem !important;
}
.txtright { text-align: right; }
  @import '../../assets/dist/css/custom.css'  
</style>